export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'title',
    label: 'Tên race',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'race_type',
    label: 'Thể loại',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'category',
    label: 'Loại race',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'start_at',
    label: 'Ngày bắt đầu',
    minWidth: 170,
    type: 'datetime'
  },
  {
    prop: 'end_at',
    label: 'Ngày kết thúc',
    minWidth: 170,
    type: 'datetime'
  },
  {
    prop: 'joined',
    label: 'Đã đăng ký',
    minWidth: 180,
    type: 'number'
  },
  {
    prop: 'status_format',
    label: 'Trạng thái',
    minWidth: 130,
    type: 'select',
    options: [
      {
        title: 'Đang diễn ra',
        value: 'đang diễn ra'
      },
      {
        title: 'Chưa diễn ra',
        value: 'chưa diễn ra'
      },
    ]
  }
]
